'use client'
import { useEffect } from 'react'
import useDatalayer from '@/hooks/useDatalayer'
import { onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth'
import { auth, auth as firebaseAuth } from '@/config/DBConnection'
import { useRouter } from 'next/navigation'
import { removeAuthCookies, saveUserInServer, setAuthServerToken } from '@/context/auth/authServerActions'
import Image from '@/reusableComponents/Image'
import Background from '@/assets/backgroundLogin.png'
import Doctor from '@/assets/doctorLogin.png'
import { LogoSelector } from './email/LogoSelector'
import { Button, Loader, Title } from '@umahealth/occipital'
import { useMutation } from 'react-query'
import serverBroken from '@/assets/illustrations/serverBroken.svg'
import { FirebaseError } from 'firebase/app'
import { useUpdateTokens } from './useUpdateTokens'
import { saveUtmParams } from '@/context/saveUtmParams'
import { trackEntryPage } from '@/events/signUpEvents'

export default function LoginView({
	searchParams,
} : {
	searchParams?: {
		customToken?:string,
		redirect?:string,
		verifyCache?: 'true',
		/** utm params para trackear en analytics */
		utm_source?: string,
		/** utm params para trackear en analytics */
		utm_medium?: string,
		/** utm params para trackear en analytics */
		utm_campaign?: string,
	}
}) {
	const router = useRouter()
	const signInWithCustomTokenMutation = useMutation<void, FirebaseError, string, unknown>(['signInWithCustomToken'], async(customToken : string) => {
		const credential = await signInWithCustomToken(
			firebaseAuth,
			customToken,
		)
		const idTokenResult = await credential.user.getIdTokenResult()

		if (idTokenResult.token) {
			await saveUserInServer(idTokenResult)
			await setAuthServerToken(idTokenResult.token)
		}

	})
	const updateTokens = useUpdateTokens({
		options: {
			onSuccess: () => {
				router.push( searchParams?.redirect ?? '/')
			},
			onError: async() => {
				localStorage.clear()
				removeAuthCookies()
				signOut(auth)
				window.location.replace('/login')
			}
		}
	})

	useEffect(() => {
		if (searchParams?.verifyCache === 'true'){
			const authStateChange = onAuthStateChanged(auth, (user) => {
				if (user) {
					updateTokens.mutate(user)
				}
			})
			return () => authStateChange()
		}
	}, [auth, searchParams?.verifyCache])

	useEffect(() => {
		if (searchParams){
			if (searchParams.customToken) {
				signInWithCustomTokenMutation.mutate(searchParams?.customToken, {
					onSuccess: () => {
						router.replace(searchParams?.redirect ?? '/')
					}
				})
			}
			saveUtmParams(searchParams)
		}
	}, [])

	useDatalayer({
		'instance': process.env.NEXT_PUBLIC_DATALAYER_INSTANCE,
		'page_title': 'Login',
		'content_group': 'login',
		'event': 'custom_pageview',
		'page_location': `${process.env.NEXT_PUBLIC_PAGE_LOCATION}/login`
	})

	if (signInWithCustomTokenMutation.isError) {
		return (
			<div className="flex flex-col justify-center align-center h-full w-full text-center">
				<div>
					<div className="flex justify-center mb-6">
						<Image src={serverBroken} alt=""/>
					</div>
					<Title
						hierarchy="h2"
						size="text-l"
						weight="font-bold"
						color="text-grey-1"
						className='mb-2'
					>
						El link de inicio de sesión ya expiró
					</Title>
					<p className='text-balance'>Ingrese nuevamente.</p>
					<p> error detallado: {signInWithCustomTokenMutation.error.message}</p>
				</div>
			</div>
		)
	}


	if ( (searchParams?.customToken || signInWithCustomTokenMutation.isSuccess || signInWithCustomTokenMutation.isLoading) || (searchParams?.verifyCache || updateTokens.isSuccess || updateTokens.isPending) ) {
		return (
			<div className='h-full grid place-content-center justify-center h gap-4'>
				<Loader className='m-auto' size='size-7' color='stroke-primary' />
			</div>
		)
	}


	return (
		<div className="w-full h-screen bg-transparent relative">
			<Image
				className="absolute top-0 bottom-0 left-0 right-0 -z-1 h-full object-cover"
				src={Background}
				alt="fondo"
			/>
			<div className="shadow-xl z-20 rounded-lg w-full h-full flex flex-col justify-between">
				<div className='z-20'>
					<h1 className="text-white text-lg text-center font-semibold mb-2 mt-8">
						Te damos la bienvenida a
					</h1>
					<LogoSelector white/>
				</div>
				<Image
					className="absolute bottom-0 left-0 right-0 -z-4 object-contain h-[81%]"
					src={Doctor}
					alt="doctor"
				/>
				<div className='bg-white/75 p-6 h-40 w-full z-20 flex items-center justify-center flex-col gap-6'>
					<Button
						variant="filled"
						type="button"
						size="full"
						action={() => {
							trackEntryPage('signup')
							router.push('/signup')
						}}
					>
						Crear nueva cuenta
					</Button>
					<Button
						className='bg-white'
						variant="outlined"
						type="button"
						size="full"
						action={() => {
							trackEntryPage('login')
							router.push('/login/email')
						}}
					>
						Iniciar sesión
					</Button>
				</div>
			</div>
		</div>
	)
}