import posthog from 'posthog-js'

/**
 * Propiedades comunes para todos los eventos de autenticación.
 */
interface AuthEventProperties {
	step?: string
	text?: string
	action?: string
}

/**
 * Envía un evento a PostHog relacionado con el flujo de autenticación.
 * 
 * @param action - La acción específica dentro del módulo de autenticación.
 * @param properties - Propiedades adicionales que describen el evento.
 */
function trackAuthEvent(action: string, properties: AuthEventProperties): void {
	posthog.capture(`signup_${action}`, properties)
}


/**
 * Evento que se dispara cuando el usuario completa el registro.
 */
export function trackSignupFinished(): void {
	trackAuthEvent('finished', { text: 'Continuar' })
}

/**
 * Evento que se dispara cuando el usuario intenta nuevamente agregar la foto del DNI durante el registro.
 */
export function trackSignupAddDniPhotoRegisterTryAgain(): void {
	trackAuthEvent('add_dni_photo_register_try_again', { text: 'Intentar nuevamente' })
}

/**
 * Evento que se dispara cuando se agrega una foto del DNI.
 */
export function trackDniPhotoAdded(): void {
	trackAuthEvent('dni_photo_added', {})
}


/**
 * Evento que se dispara cuando se detecta un paso específico en el registro del usuario.
 *
 * @param step - El paso actual del proceso de registro detectado desde el localStorage.
 */
export function trackSignupRegisterStepDetected(step: string): void {
	trackAuthEvent('register_step_detected', { step })
}

/**
 * Evento que se dispara cuando el usuario avanza en el modal de registro.
 *
 * @param step - El paso actual del proceso de registro detectado desde el localStorage.
 * @param text - El texto descriptivo de la acción realizada por el usuario.
 */
export function trackSignupRegisterModalAdvance(step: string, text: string): void {
	trackAuthEvent('signup_register_modal_advance', { step, text })
}

/**
 * Evento que se dispara cuando el usuario decide reiniciar el proceso en el modal de registro.
 *
 * @param step - El paso actual del proceso de registro detectado desde el localStorage.
 * @param text - El texto descriptivo de la acción realizada por el usuario, en este caso 'volver a empezar'.
 */
export function trackSignupRegisterModalRestart(step: string, text: string): void {
	trackAuthEvent('signup_register_modal_restart', { step, text })
}

/**
 * Evento que se dispara cuando el usuario llega al paso de registro relacionado con WhatsApp.
 *
 * @param step - El paso actual del proceso de registro.
 * @param input - La entrada específica proporcionada por el usuario, en este caso Whatsapp.
 */
export function trackSignupRegisterStepWhatsapp(step: string, input: string): void {
	trackAuthEvent('signup_register_step_whatsapp', { step, text: input })
}

/**
 * Evento que se dispara cuando el usuario llega al paso de registro relacionado con el DNI.
 *
 * @param step - El paso actual del proceso de registro.
 * @param input - La entrada específica proporcionada por el usuario, en este caso 'dni'.
 */
export function trackSignupRegisterStepDni(step: string, input: string): void {
	trackAuthEvent('signup_register_step_dni', { step, text: input })
}

/**
 * Evento que se dispara cuando el usuario continúa en un paso específico del proceso de registro.
 *
 * @param step - El paso actual del proceso de registro.
 * @param text - El texto descriptivo de la acción realizada por el usuario, en este caso 'Continuar registro'.
 */
export function trackSignupRegisterStep(step: string, text: string): void {
	trackAuthEvent(`signup_register_step_${step}`, { step, text })
}

/**
 * Evento que se dispara cuando ocurre un error en el registro y el usuario decide volver a intentarlo.
 *
 * @param step - El paso actual del proceso de registro donde ocurrió el error.
 * @param text - El texto descriptivo de la acción realizada por el usuario, en este caso 'Error en el registro, volver a intentar'.
 */
export function trackSignupRegisterErrorTryAgain(step: string, text: string): void {
	trackAuthEvent('signup_register_error_reload_page', { step, text })
}

/**
 * Evento que se dispara cuando ocurre un error en el registro y el usuario decide comunicarse con soporte.
 *
 * @param step - El paso actual del proceso de registro donde ocurrió el error.
 * @param text - El texto descriptivo de la acción realizada por el usuario, en este caso 'Error en el registro, comunicarse con soporte'.
 */
export function trackSignupRegisterErrorCallSupport(step: string, text: string): void {
	trackAuthEvent('signup_register_error_call_support', { step, text })
}

/**
 * Evento que se dispara cuando el usuario agrega una foto del DNI durante el registro.
 *
 * @param step - El paso actual del proceso de registro.
 * @param text - El texto descriptivo de la acción realizada por el usuario, en este caso 'Agregar foto del dni'.
 */
export function trackSignupRegisterAddDniPhoto(step: string, text: string): void {
	trackAuthEvent('signup_register_add_dni_photo', { step, text })
}

/**
 * Evento que se dispara cuando el usuario acepta los términos y condiciones durante el registro.
 *
 * @param step - El paso actual del proceso de registro.
 * @param text - El texto descriptivo de la acción realizada por el usuario, en este caso 'Aceptar términos y condiciones'.
 */
export function trackSignupRegisterPrivacyCheck(step: string, text: string): void {
	trackAuthEvent('signup_register_privacy_check', { step, text })
}


/**
 * Evento que se dispara cuando el usuario se registra utilizando un correo electrónico.
 */
export function trackSignupWithEmail(): void {
	trackAuthEvent('signup_with_email', {})
}

/**
 * Evento que se dispara cuando el usuario intenta iniciar sesión con un correo electrónico.
 *
 * @param text - El texto descriptivo de la acción realizada por el usuario, en este caso '¿Ya tienes una cuenta?'.
 */
export function trackSignupLoginWithEmail(text: string): void {
	trackAuthEvent('signup_login_with_email', { text })
}

/**
 * Evento que se dispara cuando ocurre un error en el primer paso del registro con correo electrónico.
 *
 * @param text - El texto descriptivo del error, en este caso 'Este email ya se encuentra en uso'.
 */
export function trackSignupWithEmailStepOneError(text: string): void {
	trackAuthEvent('signup_with_email_step_one_error', { text })
}


/**
 * Evento que se dispara cuando el primer paso del registro con correo electrónico se completa con éxito.
 */
export function trackSignupWithEmailStepOneSuccess(): void {
	trackAuthEvent('signup_with_email_step_one_success', {})
}


/**
 * Evento que se dispara cuando el usuario finaliza el proceso de registro.
 *
 * @param step - El paso actual del proceso de registro.
 * @param text - El texto descriptivo de la acción realizada por el usuario, en este caso 'Finalizar registro'.
 */
export function trackSignupRegisterStepEnd(step: string, text: string): void {
	trackAuthEvent('signup_register_step_end', { step, text })
}

/**
 * Evento que se dispara cuando el usuario se registra utilizando un proveedor externo (como Google, Facebook, etc.).
 *
 * @param providerId - El identificador del proveedor utilizado para el registro.
 */
export function trackSignupWithProvider(providerId: string): void {
	trackAuthEvent('signup_with_provider', { text: providerId })
}


/**
 * Envía un evento de entrada a la página principal de la aplicación, 
 * indicando si el usuario opta por registrarse o iniciar sesión.
 * 
 * @param {'signup' | 'login'} action - La acción que el usuario seleccionó, 
 * ya sea 'signup' (registrarse) o 'login' (iniciar sesión).
 * 
 * @returns {void} No retorna ningún valor.
 */
export function trackEntryPage(action: 'signup' | 'login'): void {
	trackAuthEvent('patient_entry_page', {
		action
	})
}
